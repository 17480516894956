@import 'Styles/Tillskararakademin/includes';

/**
*
* Grid
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Grid.Base.module';

.Grid {
    $root: &;

    &__Container {
        padding-top: 45px;
        padding-bottom: 45px;

        @include media(lg) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include media(xl) {
            padding-left: 20px;
            padding-right: 20px;
        }

        #{$root}--ArchiveList & {
            padding-top: 65px;
        }
    }

    a {
        :global(.PageOfferSchool) & {
            padding: 10px 20px;
        }
    }

    &__Title {
        margin-bottom: 10px;
        font-family: $font-base;
        font-size: 1.6rem;
        font-weight: $medium;
        text-align: center;
        text-transform: uppercase;

        @include media(md) {
            margin-bottom: 30px;
        }

        #{$root}--CardNews & {
            text-align: left;
            font-family: $font-heading;
            font-weight: $bold;
            font-size: 2.8rem;
            text-transform: none;
            margin-bottom: 24px;

            @include media(md) {
                margin-bottom: 24px;
            }
        }

        :global(.PageOfferSchool) & {
            margin-bottom: 25px;
            text-align: left;

            @include media(md) {
                margin-bottom: 30px;
            }
        }
    }

    &__Text {
        font-size: 2rem;
    }

    &__Grid {
        margin-left: -10px;
    }

    &__Item {
        margin-left: 10px;
        margin-bottom: 10px;

        @include media(sm) {
            width: calc(50% - 10px);

            #{$root}--Full & {
                width: 100%;
            }
        }

        @include media(lg) {
            width: calc(#{percentage(1/3)} - 10px);
        }

        #{$root}--CardArticleSubject &,
        #{$root}--Fourcol & {
            @include media(xs) {
                width: calc(50% - 10px);
            }

            @include media(sm) {
                width: calc(#{percentage(1/3)} - 10px);
            }

            @include media(lg) {
                width: calc(25% - 10px);
            }
        }
    }

    &__Button {
        margin-top: 40px;
        text-align: center;
    }

    &__Link {
        @include u-button(solid large green);
    }

    + .Grid {
        padding-top: 25px;
    }
}
